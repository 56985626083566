.flat-button{
    color: #ffd700;
    font-size: 13px;
    background: none;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;
    &:hover{
        background: #ffd700;
        color: #333;
    }
}
.project-list{
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;

    .face{
        display: flex;
        flex-direction: column;
        .project-link{
            display: flex;
            flex-direction: row;
            
        }
        .code-link{
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            
        }
        .project-link>p{
            font-size: 18px;
            margin-left: 14px;
        }
        .code-link>p{
            font-size: 18px;
            margin-left: 14px;
        }
        

    }
    .bord{
        height: 1px;
        border: 1px solid rgb(7, 251, 7);
        width: 90%;
        margin-bottom: 10px;
    }
}